
<template>
    <v-dialog
      v-model="show"
      width="80%"
      persistent
      :dark="$dark.get()"
      @click:outside="$emit('close-modal')"
    >
      <v-card>
        <v-card-title class="justify-center">
          Для совершения данного действия необходимо ввести пароль
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            label="Укажите пароль"
            :error="error"
            @click:append="showPass = !showPass"
            @input="error = false"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-6">
          <v-btn class="mr-12 text-capitalize" @click="$emit('close-modal')">Отмена</v-btn>
          <v-btn class="text-capitalize" color="#5d4bd0" dark @click="checkPass()">Подтвердить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import ShowMessage from "@/Functions/message";
  
  export default {
    name: "PasswordDialogCouries",
    props: {
      show: {
        type: Boolean,
        default: true,
      },
      // Принимаем один пароль для всех курьеров
      courierPassword: {
        type: String,
        required: true,  // Пароль должен быть передан
      },
    },
    data() {
      return {
        password: "",
        showPass: false,
        error: false,
      };
    },
    methods: {
      checkPass() {
        if (this.password !== this.courierPassword) {
          ShowMessage("Неверный пароль");
          this.error = true;
          return;
        }
        this.$emit("close-modal", true);
        this.password = "";
      },
    },
  };
  </script>