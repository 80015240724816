<template>
  <v-card>
    <!-- Диалог простого удаления (без причины) -->
    <delete-dialog
      :show="deleteDialog"
      @close-modal="closeModal()"
      @delete-modal="closeModal(true)"
    />

    <!-- Диалог отмены (с выбором причины) -->
    <delete-dialog
      :show="cancelOrderDialog"
      :delete-button-text="'Отменить Заказ'"
      @close-modal="closeCancelModal()"
      @delete-modal="closeCancelModal(true)"
    >
      <template v-slot:body>
        <!-- Причина отмены -->
        <v-select
          v-model="selectedCancelTag"
          label="Причина"
          :items="STATUS.cancelTags"
          item-text="name"
          item-value="id"
          style="max-width: 90%; margin: 0 auto"
        />
      </template>
    </delete-dialog>

    <!-- Диалог возврата (при онлайн-оплате) -->
    <ReturnOrder
      :show="returnDialog"
      :order="selectedOrder"
      @close-modal="closeReturnDialog($event)"
    />

    <!-- Таблица заказов -->
    <v-simple-table fixed-header class="orders_wrapper" width="100%">
      <thead>
        <tr class="orders_header">
          <!-- Чекбокс -->
          <th
            v-if="$power.check('admin_order_edit') || $power.check('admin_order_delete')"
            class="orders_head"
          ></th>
          <th class="orders_head">№</th>
          <th class="orders_head">ID</th>
          <th class="orders_head">Создан</th>
          <th class="orders_head">Доставлен</th>
          <th class="orders_head">Клиент</th>
          <th class="orders_head">Адрес</th>
          <th style="max-width: 95px" class="orders_head">Стоимость</th>

          <!-- Филиал -->
          <th style="max-width: 130px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="departments"
              name="Филиал"
              items-name="name"
              input-key="departmentIds"
              @change="$emit('set-input')"
            />
          </th>

          <!-- Тип оплаты -->
          <th style="max-width: 160px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="payments"
              name="Тип оплаты"
              items-name="name"
              input-key="paymentTypes"
              @change="$emit('set-input')"
            />
          </th>

          <!-- Статус оплаты -->
          <th style="max-width: 110px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="STATUS.payStatus"
              name="Статус оплаты"
              items-name="name"
              input-key="paymentStatuses"
              @change="$emit('set-input')"
            />
          </th>

          <!-- Статус заказа -->
          <th style="max-width: 100px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="statuses"
              name="Статус"
              items-name="name"
              input-key="statuses"
              @change="$emit('set-input')"
            />
          </th>

          <!-- Курьер -->
          <th style="max-width: 100px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="courier"
              name="Курьеры"
              items-name="username"
              input-key="couriers"
              @change="$emit('set-input')"
            />
          </th>

          <!-- Сборщик -->
          <th style="max-width: 100px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="picker"
              name="Сборщик"
              items-name="username"
              input-key="pickers"
              @change="$emit('set-input')"
            />
          </th>

          <th class="orders_head"></th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in orders"
          :key="item.id"
          class="orders_row"
        >
          <!-- Чекбокс (выделение) -->
          <td
            v-if="$power.check('admin_order_edit') || $power.check('admin_order_delete')"
            class="orders_checkbox"
          >
            <v-checkbox
              v-model="checks[index].state"
              hide-details
              class="checkbox"
            />
          </td>

          <!-- № в таблице -->
          <td class="orders_item" style="width: 20px">
            {{ index + 1 }}
          </td>

          <!-- ID заказа -->
          <td class="orders_item" style="width: 50px">
            {{ item.orderId || "0" }}
          </td>

          <!-- Дата создания -->
          <td class="orders_item" style="width: 50px; text-align: center">
            {{ getNormalDate(item.date) || "Не указана" }}
          </td>

          <!-- Дата доставки -->
          <td class="orders_item" style="width: 50px">
            {{ getNormalDate(item.deliveryDate) || "Не указана" }}
          </td>

          <!-- Клиент -->
          <td class="orders_item" style="width: 120px">
            {{ item.client }}
          </td>

          <!-- Адрес -->
          <td class="orders_item" style="width: 120px">
            {{ item.address }}
          </td>

          <!-- Стоимость -->
          <td style="text-align: center" class="orders_item">
            {{ item.price }}
          </td>

          <!-- Филиал (v-select) -->
          <td style="max-width: 130px" class="orders_item">
            <v-select
              :value="item.department"
              :items="departments"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              :readonly="$root.isProvider"
              @change="$emit('change-department', {
                order: item.orderId,
                department: $event
              })"
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Тип оплаты (v-select) -->
          <td style="max-width: 160px" class="orders_item">
            <v-select
              :value="item.paymentType"
              :items="payments"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              :readonly="$root.isProvider"
              @change="$emit('change-payment', {
                order: item.orderId,
                payment: $event
              })"
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Статус оплаты (v-select) -->
          <td style="max-width: 110px" class="orders_item">
            <v-select
              :background-color="getStatusColor(item.paymentStatus)"
              :value="item.paymentStatus"
              :items="STATUS.payStatus"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              :readonly="$root.isProvider"
              @change="$emit('change_pay_status', {
                statusId: $event,
                orderId: item.orderId
              })"
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Статус заказа (v-select) -->
          <td style="width: 100px" class="orders_item">
            <v-select
              :value="item.status"
              :items="statuses"
              item-text="name"
              item-value="id"
              :background-color="getStatusColor(item.status)"
              hide-details
              class="orders_input"
              dense
              :readonly="$root.isProvider"
              @change="
                emitChangeStatus({
                  order: item.orderId,
                  status: $event,
                  paymentStatus: item.paymentType,
                  courier: item.courier
                })
              "
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Курьер (с выводом "Яндекс Курьер" при id=1767) -->
          <td style="max-width: 100px" class="orders_item">
            <v-select
              :items="decorateCouriers(item, courier)"
              :value="normalizeCourier(item.courier)"
              item-text="displayName"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              :readonly="$root.isProvider || isSelfDelivery(item)"
              @change="emitChangeCourier({ order: item.orderId, courier: $event })"
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Сборщик (v-select) -->
          <td style="max-width: 100px" class="orders_item">
            <v-select
              v-model="item.picker"
              :items="picker"
              item-text="username"
              item-value="id"
              dense
              hide-details
              :readonly="$root.isProvider"
              class="orders_input"
              @change="$emit('change-picker', {
                order: item.orderId,
                picker: $event
              })"
            >
              <template #append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>

          <!-- Кнопка "Подробнее" -->
          <td style="max-width: 40px" class="orders_item">
            <v-btn
              color="#F3993E"
              icon
              x-large
              @click="
                orderId = item.orderId;
                show = true;
              "
            >
              <v-icon>mdi-text-box</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <!-- Кнопка "Показать ещё" -->
    <v-card-actions class="orders_action">
      <v-btn
        v-if="orders.length < last"
        color="#51C8F0"
        class="order-btns"
        @click="$emit('load-more')"
      >
        Показать еще 15 заказов
      </v-btn>
    </v-card-actions>

    <!-- Диалог пароля (статус доставлен) -->
    <PasswordDialog
      :show="showPassDialog"
      @close-modal="closeCancelModal($event)"
    />
    <PasswordDialogCouries
      :show="showPassDialog2"
      :courier-password="courierPassword"
      @close-modal="closeCancelModal($event)"
    />

    <!-- Детальная инфо о заказе -->
    <order-info
      :show="show"
      :order-id="orderId"
      @close_modal="show = false"
      @change_pay_status="$emit('change_pay_status', $event)"
    />

    <!-- Bottom bar (удаление/редактирование) -->
    <bottom-bar
      :id="id"
      :active="active"
      :edit="$power.check('admin_order_edit')"
      :delet="$power.check('admin_order_remove')"
      :disable="states.length > 1"
      order="order"
      @show="deleteDialog = true"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import OrderInfo from "@/components/Order/OrderInfo";
import PasswordDialogCouries from './PasswordDialogCouries.vue';
import BottomBar from "../BottomBar";
import DeleteDialog from "../DeleteDialog";
import PasswordDialog from "@/components/Order/PasswordDialog";
import ReturnOrder from "@/components/Order/ReturnOrder";
import AutoComplete from "@/components/Order/AutoComplete";
import { mapGetters, mapActions } from "vuex";
import showMessage from "@/Functions/message";

export default {
  components: {
    BottomBar,
    OrderInfo,
    DeleteDialog,
    ReturnOrder,
    PasswordDialog,
    PasswordDialogCouries,
    AutoComplete,
  },
  props: {
    items: { type: Array, default: null },
    checks: { type: Array, default: null },
    statuses: { type: Array, default: null },
    payments: { type: Array, default: null },
    departments: { type: Array, default: null },
    picker: { type: Array, default: null },
    courier: { type: Array, default: null },
    last: { type: Number, default: 0 },
    input: { type: Object, default: null },
  },
  data() {
    return {
      orderId: null,            // ID для <order-info>
      show: false,              // показать <order-info>
      deleteDialog: false,      // диалог простого удаления
      cancelOrderDialog: false, // диалог отмены (с причиной)
      showPassDialog2: false,   // диалог пароля (статус 8)
      showPassDialog: false, 
      changeCourierPass: false,
      returnDialog: false,      // диалог возврата (онлайн-оплата)
      canBeCanceled: false,     // флаг для повторных вызовов
      selectedOrder: {},        // текущий заказ (для диалогов)
      selectedCancelTag: {},    // причина отмены
      yandexCancelState: null,  // сохраняем cancel_state (free/paid)
      courierPassword: "couriers212",
    };
  },
  computed: {
    ...mapGetters({
      STATUS: "Status/STATE",
    }),
    // Сортируем заказы, обрабатываем "Самовывоз"
    orders() {
      let items = this.items || [];
      let sorted = [];
      if (this.input?.sorted === "createdAt") {
        sorted = items.sort((a, b) => new Date(a.date) - new Date(b.date));
      } else {
        sorted = items.sort((a, b) => b.orderId - a.orderId);
      }
      // Превращаем "deliveryOption=Самовывоз" в courier={id:666,...}
      sorted.forEach((it) => {
        if (it.deliveryOption === "Самовывоз") {
          it.courier = { username: "Самовывоз", id: 666 };
        }
      });
      return sorted;
    },
    states() {
      return this.checks.filter((x) => x.state === true);
    },
    active() {
      return this.states.length > 0 ? "active" : "";
    },
    id() {
      return this.states.length !== 0 ? this.states[0].id : null;
    },
  },
  methods: {
    ...mapActions({
      getSingle: "Order/GET_ORDER",            // для возврата
      checkYandexCancel: "Order/CHECK_YANDEX_CANCEL", // проверка яндекс
      cancelYandex: "Order/CANCEL_YANDEX",            // отмена яндекс
    }),

    // Форматирование даты
    getNormalDate(val) {
      if (!val) return null;
      const arr = val.split(",");
      if (arr.length > 1) {
        return moment(arr[0]).format("DD.MM HH:mm") + ", Предзаказ";
      }
      return moment(val).format("DD.MM HH:mm");
    },

    // Если courier.id=666 => самовывоз
    isSelfDelivery(order, couriers) {
      const selfDel = order.courier?.id === 666;
      if (couriers && Array.isArray(couriers)) {
        if (selfDel) {
          const arr = [...couriers];
          arr.push({ username: "Самовывоз", id: 666 });
          return arr;
        }
        return couriers;
      }
      return selfDel;
    },

    // Отображаем "Яндекс Курьер" при id=1767
    decorateCouriers(order, couriers) {
      // 1) сначала обрабатываем самовывоз
      let arr = this.isSelfDelivery(order, couriers);
      if (!Array.isArray(arr)) arr = [];
      // 2) если заказ courier === 1767 числом, но не в списке, добавим
      if (typeof order.courier === "number" && order.courier === 1767) {
        const found = arr.find((c) => c.id === 1767);
        if (!found) {
          arr.push({ id: 1767, username: "Яндекс Курьер", displayName: "Яндекс Курьер" });
        }
      }
      // 3) Меняем displayName
      return arr.map((c) => {
        return {
          ...c,
          displayName: c.id === 1767 ? "Яндекс Курьер" : c.username,
        };
      });
    },

    // Нормализуем courier, если приходит числом
    normalizeCourier(courier) {
      if (typeof courier === "number") {
        if (courier === 1767) {
          return { id: 1767, username: "Яндекс Курьер", displayName: "Яндекс Курьер" };
        }
        // Другие числа, если нужны
        return { id: courier, username: String(courier), displayName: String(courier) };
      }
      return courier;
    },

    // Диалог возврата (старая логика)
    closeReturnDialog(payload) {
      if (payload) {
        this.canBeCanceled = true;
        if (payload.refundAll) {
          // полный возврат => ставим статус
          this.emitChangeStatus(this.selectedOrder);
          return;
        }
        this.$emit("render-orders");
      } else {
        this.selectedOrder = {};
        this.$emit("render-orders");
      }
      this.returnDialog = false;
    },

    // Диалог удаления (без причины)
    closeModal(del) {
      if (del) {
        this.$emit("delete-button");
      }
      this.deleteDialog = false;
    },

    // Диалог "Отменить Заказ"
    async closeCancelModal(del) {
      if (del) {
        // пользователь подтвердил отмену
        this.canBeCanceled = true;

        if (this.changeCourierPass) {
          // Смена курьера с паролем
          this.emitChangeCourier(this.selectedOrder);
        } else {
          // Если курьер = Яндекс (1767)
          if (
            this.selectedOrder.courier &&
            (typeof this.selectedOrder.courier === "number"
              ? this.selectedOrder.courier === 1767
              : this.selectedOrder.courier.id === 1767)
          ) {
            console.log("[closeCancelModal] Отмена Яндекс, orderId:", this.selectedOrder.orderId);
            console.log("[closeCancelModal] cancelState =", this.yandexCancelState);

            const response = await this.cancelYandex({
              orderId: this.selectedOrder.orderId, // <-- теперь не undefined
              cancelState: this.yandexCancelState,
            });
            console.log("[closeCancelModal] cancelYandex ответ:", response);

            // Если сервер возвращает 200 c пустым телом => response=null => считаем успех
            if (!response || response.success) {
              showMessage("Заказ успешно отменен (Яндекс)", true);
              // Принудительно ставим статус=9 (отменён)
              this.$emit("change-status", {
                order: this.selectedOrder.orderId,
                status: 9,
                tag: this.selectedCancelTag,
              });
              this.$emit("render-orders");
            } else {
              showMessage(response?.response || "Ошибка при отмене Яндекс-заказа");
            }
          } else {
            // Обычный заказ
            this.emitChangeStatus(this.selectedOrder);
          }
        }
      } else {
        // пользователь отменил диалог
        this.selectedOrder = {};
        this.$emit("render-orders");
      }

      this.cancelOrderDialog = false;
      this.showPassDialog = false;
      this.changeCourierPass = false;
      this.yandexCancelState = null;
    },

    // Смена курьера (с паролем)
    emitChangeCourier(item) {
      this.selectedOrder = item;
      this.changeCourierPass = true;

      if (!this.canBeCanceled) {
        // Для всех курьеров передаем один и тот же пароль
        const courierPassword = this.courierPassword;

        // Передаем пароль в PasswordDialog
        this.showPassDialog2 = true;
        this.$nextTick(() => {
          this.setCourierPassword(courierPassword);
        });
        return;
      }
      this.$emit("change-courier", this.selectedOrder);
      this.selectedOrder = {};
      this.canBeCanceled = false;
      this.changeCourierPass = false;
    },

    // Устанавливаем пароль
    setCourierPassword(password) {
      this.courierPassword = password;
    },
    // Главный метод смены статуса
    async emitChangeStatus(item) {
      console.log("[emitChangeStatus] Новый статус =", item.status, ", заказ =", item.order);

      // Для возврата (статус=10, онлайн-оплата)
      let products = [];
      if (!item.products && item.status === 10 && item.paymentStatus >= 4) {
        const data = await this.getSingle(item.order);
        products = data.products;
      }

      // -- ВАЖНО: сохраняем orderId, чтобы не было undefined:
      this.selectedOrder = {
        ...item,
        orderId: item.order, // <-- делаем так, чтобы closeCancelModal() имел orderId
        products,
      };

      // Если "доставлен" (8) => пароль
      if (item.status === 8 && !this.canBeCanceled) {
        this.showPassDialog = true;
        return;
      }

      // Если "возврат" (10) при онлайн-оплате => диалог
      if (item.status === 10 && item.paymentStatus >= 4 && !this.canBeCanceled) {
        this.returnDialog = true;
        return;
      }

      // Если "отменён" (9)
      if (item.status === 9 && !this.canBeCanceled) {
        // Проверяем курьера=Яндекс (1767)?
        if (
          item.courier &&
          (typeof item.courier === "number"
            ? item.courier === 1767
            : item.courier.id === 1767)
        ) {
          console.log("[emitChangeStatus] Заказ Яндекс, checkYandexCancel...");
          const checkResp = await this.checkYandexCancel(item.order);
          console.log("[emitChangeStatus] ответ checkYandexCancel:", checkResp);

          // Если можно отменить (free/paid)
          if (checkResp && (checkResp.cancel_state === "free" || checkResp.cancel_state === "paid")) {
            console.log("[emitChangeStatus] Можно отменить (Яндекс), cancel_state =", checkResp.cancel_state);
            this.yandexCancelState = checkResp.cancel_state;
            this.cancelOrderDialog = true;
          } else {
            console.log("[emitChangeStatus] Нельзя отменить Яндекс:", checkResp?.response);
            showMessage(checkResp?.response || "Отмена Яндекс курьера невозможна");
          }
          return;
        } else {
          // Обычный курьер => диалог причины
          console.log("[emitChangeStatus] Обычный курьер -> cancelOrderDialog");
          this.cancelOrderDialog = true;
          return;
        }
      }

      // Если никакие особые условия не подошли => просто меняем статус
      console.log("[emitChangeStatus] Присваиваем статус напрямую...");
      this.$emit("change-status", {
        ...this.selectedOrder,
        tag: this.selectedCancelTag,
      });
      this.selectedOrder = {};
      this.canBeCanceled = false;
      this.yandexCancelState = null;
    },

    // Цвет для статуса оплаты
    getStatusColor(item) {
      switch (item) {
        case 1: return "#d0ca02";
        case 2: return "#e2ac54";
        case 3: return "#85C5E0";
        case 4: return "#df8956";
        case 5: return "#686a69";
        case 6: return "#3A9FC9";
        case 7: return "#fbff00";
        case 8: return "#249641";
        case 9: return "#929594";
        default: return "#929594";
      }
    },
  },

  mounted() {
    console.log("[OrderTable] mounted - courier props:", this.courier);
    console.log("[OrderTable] mounted - picker props:", this.picker);
  },

  watch: {
    courier(newVal) {
      console.log("[OrderTable] courier updated:", newVal);
    },
    picker(newVal) {
      console.log("[OrderTable] picker updated:", newVal);
    },
  },
};
</script>

<style lang="scss">
.orders {
  &_wrapper {
    box-sizing: border-box;
    .v-data-table__wrapper table {
      border-spacing: 0 1px;
    }
  }
  &_input {
    .v-input__slot {
      padding: 5px !important;
    }
    border: 1px solid #e93e7b;
    border-radius: 5px;
    align-items: center;
    .v-input__slot::before {
      border-style: none !important;
    }
    .v-select__selection--disabled {
      color: black !important;
    }
  }
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  &_head {
    background: none !important;
    color: white !important;
    padding: 0 2px !important;
    font-size: 16px !important;
  }
  &_item {
    padding: 10px 2px !important;
    border-bottom: solid 1px #e93e7a45 !important;
    &:nth-child(2) {
      padding-left: 15px !important;
    }
  }
  &_checkbox {
    padding: 0 0 0 7px !important;
    width: 20px;
    border-bottom: solid 1px white !important;
  }
  &_action {
    justify-content: center;
  }
}
.order-btns {
  text-transform: none;
}
.checkbox {
  margin: auto;
}
</style>
